<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top py-2 justify-content-lg-center">
    <div class="container-fluid p-0 col-lg-10">
      <a class="navbar-brand" href="#"><object type="image/svg+xml" :data="require('@/assets/logo.svg')" /></a>
      <button type="button" class="btn connect d-block d-lg-none" @click="connect">
        {{ maskAddress ? maskAddress : "Connect Wallet" }}
      </button>
      <button
        class="navbar-toggler collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="d-block d-lg-none mobile-menu">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="/#">Discord</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="https://opensea.io/collection/taipei-bug">Opensea</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="https://twitter.com/BugTaipei">Twitter</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="https://www.instagram.com/taipei.bug/">Instagram</a>
            </li>
          </ul>
        </div>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" @click="scrollToTop">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#rule">發行規則</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#about">關於台北米蟲</a>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="d-md-flex align-items-center me-lg-3">
              <button type="button" class="btn connect" @click="connect">
                {{ maskAddress ? maskAddress : "Connect Wallet" }}
              </button>
            </li>
            <li>
              <a class="nav-link nav-icon" href="https://opensea.io/collection/taipei-bug" target="_blank"
                ><img src="../assets/opensea.png" width="32" height="32"
              /></a>
            </li>
            <li>
              <a class="nav-link nav-icon" href="#" target="_blank"
                ><img src="../assets/discord.png" width="32" height="32"
              /></a>
            </li>
            <li>
              <a class="nav-link nav-icon" href="https://twitter.com/BugTaipei" target="_blank"
                ><img src="../assets/twitter.png" width="32" height="32"
              /></a>
            </li>
            <li>
              <a class="nav-link nav-icon" href="https://www.instagram.com/taipei.bug/" target="_blank"
                ><img src="../assets/ig.png" width="32" height="32"
              /></a>
            </li>
          </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ConnectWallet } from "../services/wallet";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Header",
  setup() {
    const store = useStore();
    let address = computed(function () {
      return store.state.address;
    });
    function setAddress(address) {
      store.commit("setAddress", address);
    }
    return {
      address,
      setAddress,
    };
  },
  data: () => {
    return {
      maskAddress: null,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    connect: function () {
      ConnectWallet()
        .then((data) => {
          this.setAddress(data);
          this.maskAddress = data.replace(
            data.substring(4, data.length - 4),
            "..."
          );
        })
        .catch((err) => {
          this.$swal(err.message, "", "error");
        });
    },
  },
};
</script>

<style scoped>
.navbar-brand {
  font-size: 24px;
}
.nav-icon img {
  width: 45px;
  height: 45px;
}
.bg-dark {
  background-color: black !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
  margin: 0 25px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0;
    padding-left: 0;
}
.connect {
  background-color: #F9B53A;
}
@media (max-width: 991.97px) {
  .navbar-dark .navbar-toggler-icon {
    background-image: url("../assets/xmark.png");
    width: 14px;
    height: 16px;
  }
  .collapsed .navbar-toggler-icon {
    background-image: url("../assets/hamburger.png");
    width: 18px;
    height: 14px;
  }
  .mobile-menu {
    height: 100vh;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  nav {
    padding: 16px;
  }
  .navbar-dark .navbar-nav {
    text-align: left;
  }
  .navbar-dark .navbar-nav .nav-link {
    margin: 0 5px;
    margin-top: 20px;
  }
  nav {
    border-bottom: 1px solid #26292D;
  }

  .navbar-collapse {
    border-top: 1px solid #26292D;
  }
}
</style>
