const { ethers } = require("ethers");
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

function hash(address, number) {
  let check_summed_address = ethers.utils.getAddress(address);
  if (check_summed_address != address) {
    console.log(`checksum ${address} to ${check_summed_address}`);
  }
  return Buffer.from(
    ethers.utils.solidityKeccak256(["address", "uint256"], [address, number]).slice(2),
    "hex"
  );
}

export function Proof(address, number, whitelist) {
  const merkleTree = new MerkleTree(
    Object.entries(whitelist).map((item) => hash(...item)),
    keccak256,
    { sortPairs: true }
  );
  console.log(999999999999, merkleTree.getHexRoot());

  return merkleTree.getHexProof(
    hash(address, number)
  );
}