import { Proof } from './merkle';

const Web3 = require("web3");
const ABI = require("./bug.json");
const bigNumber = require("bignumber.js");
const web3 = new Web3(window["ethereum"]);
const contractAddress = "0x66EB283ccEA26Af20CD14D9b9Ecfc5C9ABFCda35";
const prod = true;
const whiteList = require("./whitelist.json");

export async function ConnectWallet() {
  if (window["ethereum"]) {
    const address = await window["ethereum"].enable();
    const returned_address = address.length ? address[0] : null;
    if (returned_address) {
      const netID = await web3.eth.net.getId();
      // in prod ==1 valid
      if (netID != 1 && prod) {
        throw new Error("Please connect to Ethereum Mainnet");
      } else {
        return returned_address;
      }
    } else {
      throw new Error("No wallet is connected");
    }
  } else {
    throw new Error("Please install Metamask");
  }
}

export async function GetWhiteListSaleMintPrice() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.whiteListSaleMintPrice().call().then(price => price);
}

export async function GetWhiteListSaleStartTime() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.whiteListSaleStartTime().call().then(time => time);
}

export async function GetWhiteListSaleEndTime() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.whiteListSaleEndTime().call().then(time => time);
}

export async function GetWhiteListSaleRemainingCount() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.whiteListSaleRemainingCount().call().then(amount => amount);
}

export async function Buy(address, amount, price, proof) {
  if (!contractAddress) {
    throw new Error(
      "Please come back when public mint starts. Follow us on Twitter."
    );
  } else if (!address) {
    throw new Error("Please connect wallet first!");
  } else {
    let contract = new web3.eth.Contract(ABI, contractAddress);
    let itemPrice = new bigNumber(price);
    let sumAmount = itemPrice.multipliedBy(amount);
    console.log(88888888888888888888888, amount, proof)
    return contract.methods
      .buyBug(proof, amount)
      .estimateGas({ from: address, value: sumAmount })
      .then(function(gasAmount) {
        let gasLimit = new bigNumber(gasAmount)
          .multipliedBy(110)
          .dividedBy(100)
          .toFixed(0);
        return contract.methods
          .buyBug(proof, amount)
          .send({ value: sumAmount, from: address, gasLimit: gasLimit })
          .then(res => {
            return res.transactionHash;
          });
      })
      .catch(err => {
        console.log('buy err', err.message)
        let msg = err.message
        if (msg.includes("whiteListPurchased already")) {
          msg = "This address has already purchased";
        } else if (msg.includes("insufficient funds")) {
          msg = "Insufficient wallet balance";
        } else if (msg.includes("failed to verify WL merkle root")) {
          msg = "Failed to verify in whitelist";
        } else if (msg.includes("not started")) {
          msg = "mint not yet started";
        } else if (msg.includes("no active sale right now")) {
          msg = "mint not yet started";
        } else {
          msg = "mint failed";
        }
        throw new Error(msg);
      });
  }
}

export function GetProof(address) {
  return Proof(address, 1, whiteList)
}
