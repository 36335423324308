import { createApp, h } from 'vue'
import { createStore } from "vuex"
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const routes = {
  '/': App
}

const Router = {
  data: () => ({
    currentRoute: window.location.pathname
  }),

  computed: {
    CurrentComponent() {
      return routes[this.currentRoute] || App
    }
  },

  render() {
    return h(this.CurrentComponent)
  }
}

const store = createStore({
  state: {
    address: null
  },
  mutations: {
    setAddress (state, addressIn) {
      state.address = addressIn
    }
  }
})

const app = createApp(Router)
app.use(VueSweetalert2)
app.use(store)
app.mount('#app')
