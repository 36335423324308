<template>
  <footer class="text-start text-white">
    <ul>
      <li>
        <object type="image/svg+xml" :data="require('@/assets/logo.svg')" />
      </li>
      <li>
        <a class="nav-link" href="/#rule"><h3>發行規則</h3></a>
      </li>
      <li class="d-none d-lg-block">
        <a class="nav-link" href="/#about"><h3>關於台北米蟲</h3></a>
      </li>
      <li>
        <h3>相關連結</h3>
        <ul>
          <li>
            <a href="#" target="_blank"
              ><img src="../assets/discord.png" width="32" height="32"
            /></a>
          </li>
          <li>
            <a href="https://opensea.io/collection/taipei-bug" target="_blank"
              ><img src="../assets/opensea.png" width="32" height="32"
            /></a>
          </li>
          <li>
            <a href="https://twitter.com/BugTaipei" target="_blank"
              ><img src="../assets/twitter.png" width="32" height="32"
            /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/taipei.bug/" target="_blank"
              ><img src="../assets/ig.png" width="32" height="32"
            /></a>
          </li>
        </ul>
      </li>
    </ul>
    <!-- Copyright -->
    <div class="copy">© 2022 Taipei Bug Club</div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  padding: 50px 150px;
  background-color: black;
}
h2 {
  font-weight: 600;
font-size: 24px;
line-height: 32px;
}
h3 {
  font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #F9B53A;
}
ul {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
ul li {
  padding-right: 120px;
}
ul li ul li {
  padding-right: 25px;
}
.copy {
  margin-top: 80px;
  padding-top: 16px;
  border-top: 1px solid #26292D;
}
@media (max-width: 991.97px) {
  footer {
    padding: 32px 0;
  }
  ul li {
    padding-right: 0;
    text-align: center;
    padding-top: 30px;
  }
  ul li ul li {
    padding-right: 10px;
    padding-left: 10px;
  }
  ul {
    display: block;
  }
  ul li ul {
    display: inline-flex;
  }
  .copy {
    text-align: center;
  }
}
</style>
