<template>
  <Header />
  <section
    class="banner position-relative justify-content-lg-center d-none d-lg-block"
  >
    <img class="w-100" src="@/assets/banner.jpg" alt="" />
    <div class="banner-text w-100 row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10 text-start">
        <h1>
          <span>台北米蟲俱樂部</span>
        </h1>
        <h2>讓你外出尿急都能找到香香的廁所</h2>
        <!-- <h2 class="mt-5"><span v-if="startTime < 0 && Math.floor(time / 86400) > 0">{{ Math.floor(time / 86400) + ':' }}</span>{{ new Date( (startTime > 0 ? startTime : time) * 1000 ).toISOString().substr(11, 8) }} {{ startTime > 0 ? "後開始" : "後結束" }}</h2>
        <h2>PRICE: {{ price > 0 ? (price / 1000000000000000000) : 0 }} ETH</h2>
        <h2>REMAINING： {{ amount }}</h2>
        <button type="button" class="btn btn-mint" @click="mint">Mint</button> -->
      </div>
    </div>
  </section>

  <section class="d-block d-lg-none banner-mobile">
    <h1>
      <span>台北米蟲俱樂部</span>
    </h1>
    <h2>讓你外出尿急,</h2>
    <h2>都能找到香香的廁所</h2>
    <!-- <h2 class="mt-5"><span v-if="startTime < 0 && Math.floor(time / 86400) > 0">{{ Math.floor(time / 86400) + ':' }}</span>{{ new Date( (startTime > 0 ? startTime : time) * 1000 ).toISOString().substr(11, 8) }} {{ startTime > 0 ? "後開始" : "後結束" }}</h2>
    <h2>{{ price > 0 ? (price / 1000000000000000000) : 0 }} ETH</h2>
    <h2>REMAINING： {{ amount }}</h2>
    <button type="button" class="btn btn-mint" @click="mint">Mint</button> -->
  </section>

  <section id="rule">
    <h1 class="mb-5">發行規則</h1>
    <p>總量：999</p>
    <p class="mb-3">第一波白單鑄造日期：</p>
    <p class="mb-5">台灣時間 2/26 13:30～2/28 21:00</p>
    <p class="my-5">第一波白單主要由雷司紀既有社群中挑選</p>
    <a class="btn btn-more" href="https://rayskyinvest.notion.site/Taipei-Bug-Club-07df5aafd6d14674b9119f3228bba4c1" target="_blank">查看更多</a>
  </section>

  <section id="about" class="d-none d-lg-block">
    <h1 class="mb-5">台北米蟲</h1>
    <div class="row about-card">
      <div class="col-lg-4">
        <img class="w-100 h-100" src="@/assets/bug.jpg" alt="" />
      </div>
      <div class="col-lg-8 text-start">
        <h2>起源</h2>
        <div class="about-card-text">
          <p>你可曾想過投資是為了什麼目標嗎？</p>
          <p>
            是希望透過投資來安家立業嗎？還是為了讓下一代有更好的環境？抑或是單純想要多買一個包？
          </p>
          <p>
            Taipei Bug
            提倡「新米蟲改革」，致力於幫每個米蟲建立資產配置的良好觀念，同時不忘了好好生活，我們認為生活應該凌駕於一切之上，躺平且睡得安穩才是我們所追求的，台北米蟲團隊將持續致力於此，邀請各位米蟲們一齊參與！
          </p>
        </div>
        <a class="btn btn-more btn-hollow" href="https://rayskyinvest.notion.site/Taipei-Bug-Club-07df5aafd6d14674b9119f3228bba4c1" target="_blank">
          查看更多<span>&#8594;</span>
        </a>
      </div>
    </div>
  </section>

  <section id="qa" class="d-none d-lg-block">
    <h2>Taipei Bug Ｑ＆Ａ</h2>
    <div class="row w-75 qa-content">
      <div class="col-lg-4">
        <img src="@/assets/phone.png" alt="" />
        <h1>為何限定台北？</h1>
        <p>其實並不侷限於台北，只是取名為台北米蟲彷彿是一種象徵。</p>
      </div>
      <div class="col-lg-4">
        <img src="@/assets/layout.png" alt="" />
        <h1>白名單可鑄造幾張？</h1>
        <p>一個白名單僅能鑄造一張 NFT。</p>
      </div>
      <div class="col-lg-4">
        <img src="@/assets/people.png" alt="" />
        <h1>台北米蟲賦能為何？</h1>
        <p>
          新米蟲改革已經開始，第一個改革項目是廁所改革，你說還有其他賦能嗎？有的話，那大概就是
          Bug 吧 😏
        </p>
      </div>
    </div>
    <a class="btn btn-more" href="https://rayskyinvest.notion.site/Taipei-Bug-Club-07df5aafd6d14674b9119f3228bba4c1" target="_blank">查看更多<span>&#8594;</span></a>
  </section>

  <Footer />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import {
  GetWhiteListSaleMintPrice,
  GetWhiteListSaleStartTime,
  GetWhiteListSaleEndTime,
  GetWhiteListSaleRemainingCount,
  Buy,
  GetProof,
} from "./services/wallet";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    const store = useStore();
    let address = computed(function () {
      return store.state.address;
    });
    return {
      address,
    };
  },
  data: function () {
    return {
      price: null,
      amount: null,
      start: null,
      end: null,
      time: 0,
      startTime: 0
    };
  },
  created: function () {
    GetWhiteListSaleMintPrice()
      .then((price) => {
        this.price = price;
        console.log("price", this.price);
      })
      .catch((err) => console.log(err));

    GetWhiteListSaleStartTime()
      .then((time) => {
        this.start = time;
        console.log("start", this.start);
      })
      .catch((err) => console.log(err));

    GetWhiteListSaleEndTime()
      .then((time) => {
        this.end = time;
        console.log("end", this.end);
      })
      .catch((err) => console.log(err));
  },
  mounted: function () {
    // setInterval(this.monitor, 1000) && this.monitor();
  },
  methods: {
    getAmount: function () {
      GetWhiteListSaleRemainingCount()
        .then((amount) => {
          console.log("amount", amount);
          this.amount = amount;
        })
        .catch((err) => console.log(err));
    },
    getTime: function () {
      let now = Math.floor(new Date().getTime() / 1000);
      this.startTime = this.start - now;
      this.time = this.end - now;
      if (this.time <= 0) this.time = 0;
      console.log('startTime', this.startTime)
      console.log('time', this.time)
    },
    monitor: function () {
      this.getAmount();
      this.getTime();
    },
    mint: async function () {
      console.log(this.price);
      console.log(this.address);
      // let now = Math.floor(new Date().getTime() / 1000);
      let t =
        new Date("2022/2/26 13:30:00 GMT+08:00").getTime() -
        new Date().getTime();

      if (t > 0) {
        this.$swal({
          title: "將於 2022/2/26 13:30 開始",
          confirmButtonText: "OK",
        });
        return;
      }

      if (!this.address || !this.price) {
        this.$swal({
          title: "Please connect Metamask",
          confirmButtonText: "OK",
        });
        return;
      }

      let buyCount = 1;
      // if (now > this.end) {
      //   const { value: buyAmount } = await this.$swal({
      //     title: "How many do you want to buy?(max 3)",
      //     input: "number",
      //     inputLabel: "amount",
      //     inputAttributes: {
      //       min: 1,
      //       max: 3,
      //       step: 1,
      //     },
      //     inputValue: 1,
      //     confirmButtonText: "Buy",
      //   });
      //   buyCount = buyAmount;
      // }

      let proof = GetProof(this.address);
      Buy(this.address, buyCount, this.price, proof)
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          this.$swal(err.message, "", "error");
        });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body {
  background-color: #272728;
  margin-top: 77px;
}
h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 71px;
  letter-spacing: -0.5px;
  color: #f9b53a;
  padding-top: 12%;
}
p {
  color: white;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
}
section {
  margin: 100px auto;
}
.banner {
  margin: 0;
}
.banner h1 {
  font-size: 80px;
  font-weight: 600;
  margin-top: 20px;
}
.banner h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.1em;
  color: white;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
}
.banner h1 span,
.banner-mobile h1 span {
  background-image: linear-gradient(-90deg, #f9b53a, #ff59a8);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.banner-text {
  position: absolute;
  top: 0;
}
.btn-mint {
  background: linear-gradient(-90deg, #f9b53a, #ff59a8);
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 39px;
  color: black;
  padding: 5px 30px;
  border-radius: 10px;
}
.btn-more {
  padding: 16px 20px;
  background: #f9b53a;
  border-radius: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  color: white;
}
.btn-hollow {
  background-color: transparent;
  border: 0.997936px solid #ffffff;
}
.btn-more:hover,
.btn-hollow:hover {
  color: white;
}
.btn-hollow span,
.btn-more span {
  margin-left: 50px;
}
.about-card {
  margin: 0 auto;
  background: #1f1f21;
  border: 1.5px solid #3d3d3d;
  padding: 24px;
  width: 67%;
}
.about-card h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 57px;
  color: #f9b53a;
  text-align: start;
}
.about-card-text {
  font-size: 16px;
  margin: 32px 0px;
}
.about-card-text p {
  text-align: start;
  line-height: 28px;
  letter-spacing: 0.44px;
}
#qa p,
.qa-content > div {
  text-align: start;
}
#qa h2 {
  color: #f9b53a;
  text-align: center;
}
#qa button {
  margin: 0 auto;
}
.qa-content {
  margin: 100px auto;
}
.qa-content > div {
  background: #1f1f21;
  border-radius: 8px;
  border: 10px solid #272728;
  border-radius: 25px;
  padding: 24px;
}
.qa-content h1 {
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  margin: 15px 0px;
  color: white;
}
.qa-content p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.44px;
}
@media (max-width: 991.97px) {
  body {
    background-color: black;
  }
  .banner-mobile {
    background-image: url("./assets/mobile-bg.png");
    background-size: cover;
    margin: 0;
    padding: 120px 0 140px 0;
    margin-top: -20px;
  }
  .banner-mobile h1 {
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    color: #ffffff;
  }
  .banner-mobile h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    margin: 10px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
  }
  .banner-mobile h1 span {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
  }
  .banner-mobile .btn-mint {
    color: white;
    margin-top: 24px;
  }
  #rule {
    margin-top: 190px;
  }
}
</style>
